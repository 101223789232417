









































import {
  Component, Vue,
} from 'vue-property-decorator';

@Component({
  name: 'FailedOperation',
})
export default class FailedOperation extends Vue {
  i18n_namespace = 'components.allianz-dashboard.errors.transaction_error';

  accept() {
    this.$emit('endProcess');
  }
}

